.uom-content{
    max-height: calc(100vh - 150px);
    padding: 10px 0px 60px 0px;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    scrollbar-color: #f1f1f1 transparent;
    scrollbar-width: thin;
    &::-webkit-scrollbar {
        width: 4px;
        height: 4px;
        background-color: transparent;
        border-radius: 2px;
    }
    &::-webkit-scrollbar-thumb {
        background-color: #f1f1f1;
        border-radius: 2px;
    }
    &::-webkit-scrollbar-track {
        border: 0.0625rem solid transparent;
        border-radius: 2px;
    }
    .switch-box {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin-top: 12px;
        margin-bottom: 20px;
        .h6 {
            margin: 0px 0px;
        }
        .MuiSwitch-root {
            margin: 0 28px 0px 28px;
        }
        .p4{
            font-size: 12px;
        }
    }
    .h6{
        margin: 0px 0px 8px 0px;
    }
    .p3{
        margin: 0px 0px 20px 0px !important;
    }
    .uploads-row {
        display: flex;
        flex-wrap: wrap;    
        &>div{
            width: 106px;
            @media (max-width: 899px) {
                width: 106px;
            }
            @media (max-width: 599px) {
                width: 90px;
            }
        }
        .upload-multiple {
            width: 96px;
            height: 96px;
            position: relative;
            border-radius: 0.5rem;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            text-align: center;
            margin-left: 0px;
            overflow: hidden;
            cursor: pointer;
            @media (max-width: 899px) {
                width: 90px;
                height: 90px;
                margin-left: 0px;
            }
            @media (max-width: 599px) {
                width: 80px;
            }
            .ant-upload-wrapper{
                width: 100%;
                height: 100%;
                display: block;
                max-width: calc(100% - 0px);
                .ant-upload{
                    width: 100%;
                    height: 100%;
                    margin: auto;
                    .ant-upload{
                        flex-direction: column;
                    }
                }
            }

            .delete-icon {
                color: #E8141B;
                fill: #E8141B;
                position: absolute;
                right: 6px;
                top: 5px;
                cursor: pointer;
                z-index: 5;
            }

            .id-img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }

            .upload-icon {
                width: 32px;
                height: 32px;
                object-fit: contain;
                margin: 0px;
            }

            .upload-text {
                margin-top: 8px;
                margin-bottom: 0px;
            }
            .required-text{
                max-width: 65%;
                position: relative;
                margin-bottom: -20px;
                margin-top: -2px;
                line-height: 13px;
            }

            input[type=file] {
                position: absolute;
                z-index: 1;
                left: 0px;
                top: 0px;
                width: 100%;
                height: 100%;
                cursor: pointer;
                opacity: 0;
            }

            .progress-img{
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
                position: absolute;
                width: calc(100% - 0px);
                margin: auto;
                top: 0px;
                left: 0px;
                right: 0px;
                bottom: 0px;
                // background-color: rgba($color: $white, $alpha: .75);
                .progress-count{
                    // color: $text;
                    font-size: 24px;
                    font-family: 'SFPRODISPLAYMEDIUM';
                    display: block;
                    margin-bottom: 8px;
                }
                .progress-box{
                    height: 6px;
                    width: calc(100% - 10px);
                    border-radius: 6px;
                    overflow: hidden;
                    // background: $background;
                    margin: 3px auto;
                    position: relative;
                    .progress-content{
                        position: absolute;                            
                        left: 0px;
                        right: auto;
                        height: 6px;
                        width: auto;
                        // background: $primary;
                        border-radius: 6px;
                    }
                }
            }
        }
    }
}
.uom-footer{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px 0px;
    position: absolute;
    bottom: 0px;
    width: calc(100% - 45px);
    background: white;
    z-index: 2;
    @media (max-width: 575px) {
        flex-direction: column;
    }
    .MuiButtonBase-root.MuiCheckbox-root{
        margin: 0px 8px;
    }
    .min-width140{
        min-width: 140px;
        @media (max-width: 575px) {
            margin-top: 10px;
        }
    }
}
.id-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.redspan {
    position: absolute;
    color: #ed4731;
    bottom: -16px;
    display: block;
    font-size: 12px;
    &.bottom-extra{
        bottom: -32px;
        line-height: 16px;
    }
}
.drop-input-group {
    .MuiOutlinedInput-root {
        padding-right: 0;
    }
    // .bg-pink {
    //     .MuiOutlinedInput-root {
    //         border: none;
    //         border-left: 1px solid;
    //         border-radius: 0;
    //     }
    //     .MuiOutlinedInput-notchedOutline {
    //         border: none;
    //         border-radius: 0;
    //     }
    // }
}

.disabled {
    background: hsla(140,2%,67%,.15);
    -webkit-filter: grayscale(100%);
    filter: grayscale(100%);
}

.align-center {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}