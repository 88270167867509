// dropdown
.p-dropdown {
    background: #ffffff;
    border: 1px solid rgba(0, 0, 0, 0.23);
    transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
    border-radius: 4px;
    &:not(.p-disabled) {
        &.p-focus{
            outline: 0 none;
            outline-offset: 0;
            box-shadow: none;
            border-color: #ed4731;
        }
        &:hover {
            border-color: #000;
        }
    }
}
.p-inputtext {
    padding: 8.5px 0.75rem !important;
    color: #000 !important;
    &:enabled:focus {
        outline: 0 none;
        outline-offset: 0;
        box-shadow: none;
        border-color: #ed4731;
    }
}
.p-dropdown-panel{
    .p-dropdown-items-wrapper{
        .p-dropdown-items{
            .p-dropdown-item{
                color: #000 !important;
                .flag {
                    display: none !important;
                }
            }
        }
    }
}
.p-dropdown-label{
    .flag {
        display: none !important;
    }
}
.p-dropdown.search-drop-option{
    height: 40px !important;
    .p-dropdown-label{
        height: 40px !important;
        padding: 6.5px 14px !important;
        font-family: 'SFPRODISPLAYREGULAR' !important;
    }
    .p-dropdown-trigger{
        height: 40px !important;
    }
    *{
           font-family: 'SFPRODISPLAYREGULAR' !important;
    }
}
.maxwidth-430{
    .MuiDialogContent-root{    max-width: 430px;
    min-width: 430px;
}
.MuiDialog-paperWidthSm {
    width: 100% !important;
    max-width: 430px !important;
    border-radius: 10px !important;
    overflow: visible;
}   
.h6{
    font-size: 14px;
    margin-bottom: 10px;
    color: #252525;
}
.background-box{
    background: #FFF8F3;
    padding: 4px 16px;
    border-radius: 12px;
}

}
.before-upload{
    img.upload-img{
        width: 100%;
        height: 100%;
        object-fit: contain;
        position: absolute;
    }
}

.download-invoice{
    cursor: pointer;
}
.upload-img-parent{
    position: relative;
    .error{
        position: absolute;
        bottom: -20px;
    }
}
.overflow-auto-custome{
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    scrollbar-color: #3c4b64 transparent;
    scrollbar-width: thin;
    &::-webkit-scrollbar {
        width: 4px;
        height: 8px;
        background-color: transparent;
        border-radius: 2px;
    }
    &::-webkit-scrollbar-thumb {
        background-color: #3c4b64;
        border-radius: 2px;
    }
    &::-webkit-scrollbar-track {
        border: 0.0625rem solid transparent;
        border-radius: 2px;
    }
}
.new-modal-hed{
    margin-bottom: 8px;
    font-size: 14px;
    font-weight: 600;
}
.mt-4{
    margin-top: 16px;
}

.mb-3{
    margin-bottom: 12px;
}
.label-row{
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    .label-left{
        min-width: 100px;
        position: relative;
        display:block;
        margin-bottom: 4px;
        font-size: 14px;
    }
    .label-right{
        margin-bottom: 4px;
        position: relative;
        font-size: 14px;
    }
}
.note-text{
    padding: 8px;
    background-color: #f7ece4;
    margin-top: 20px;
    border-radius: 0.25rem;
    font-size: 12px;
}

.pay_info{
    font-size: 12px;
    color:#666;
    text-decoration: underline;
    cursor: pointer;
}

.driver-rating .MuiRating-label{
   top:4px;
}
.white-box-new-shadow{
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    background: #ffffff;
    box-shadow: 0px 0px 2px 0px #00000045;
    padding: 10px;
    img{
        width: 40px;
        height: 40px;
        border-radius: 4px;
        margin-right: 12px;
        border-radius: 1px solid #666;
        object-fit: cover;
    }
    .relative{
        .h6{
            font-size: 16px;
            font-weight: 600;
            color: #000;
            margin-bottom: 4px;
        }
        .p2{
            font-size: 12px;
            color: #3c4b64;
            margin-bottom: 0px;
        }
    }
}
.add-roles-row{
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 10px 0;
    width: 100%;
    border-bottom: 1px solid #ebebeb;
    @media (max-width: 1460px) {
        display: inline-block;
    }
    .add-roles-column{
        font-size: 14px;
        width: 10%;
        display: flex;
        flex-direction: column;
        @media (max-width: 1460px) {
            display: inline-block;
            width: 10%;
            vertical-align: text-top;
        }
        &:nth-child(1){
            width: 20%;
            padding-top: 10px;
            @media (max-width: 1460px) {
                width: 100%;
            }
        }
        &:nth-child(5){
            width: 25%;
            @media (max-width: 1460px) {
                width: 30%;
            }
        }
        &:nth-child(7){
            width: 20%;
            padding-top: 0px;
            @media (max-width: 1460px) {
                width: 30%;
            }
        }
    }
}

.store-switch-box{
    height: 100%;
    max-height: calc(100vh - 245px);
    overflow: auto;
    position: relative;
    margin: 0px -10px;
    padding: 0px 10px;
    .store-switch-flex{
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        border-bottom: 1px solid #E3E3E3;
        padding: 16px 0px;
        &:last-child{            
            border-bottom: none;
        }
        .store-logo{
            min-width: 36px;
            width: 36px;
            height: 36px;
            border-radius: 8px;
            padding: 4px;
            object-fit: scale-down;
            border: 1px solid #E8E8E8;
        }
        .relative{
            margin: 0px 8px;
            .mt-0{
                margin: 0px 0px 4px 0px;
            }
            .m-0{
                margin: 0px 0px;
            }
        }
    }
}
.cursor-pointer{
    cursor: pointer;
}