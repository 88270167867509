.aside-wrapper {
    position: fixed;
    top: 0px;
    left: 0px;
    z-index: 21;
    width: 257px;
    height: 100%;
    background-color :rgb(60 75 96);
    // box-shadow: 4px 0px 8px 0 rgb(255 255 255 / 50%);
    // border-right: 1px solid #e8e8e8;
    .dashboard_logo {
        width: 100%;
        padding: 30px;
        box-sizing: border-box;
        margin-bottom: 10px;
        margin-top: 5px;
    }
    .aside-links {
        display: flex;
        padding: 12px 32px;
        color: #252525;
        font-weight: 300;
        cursor: pointer;
        
    }
}
ul.MuiTreeView-root{
    max-height: calc(100vh - 150px);
    overflow: auto;
    margin-right: 0px;
    min-width: 100%;
}

.MuiTreeItem-root .MuiTreeItem-label{
    font-family:SFPRODISPLAYREGULAR !important;
    font-size: 15px !important;
}
.sidebar-nav .nav-group-items .nav-link{
    padding-left: 30px !important;
}