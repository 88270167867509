.store-detail-head {
    display: flex;
    align-items: center;
    margin-bottom: 16px;
    .h3 {
      margin: 0 12px 0 0;
      font-weight: 700;
      font-size: 24px;
      color: #252525;
    }
  }