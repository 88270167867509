
.product-box-row{   
    .product-box{
        border-radius: 8px;
        border:1px solid #E5E5E5;
        padding: 10px 16px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 10px;
        width: 100%;
        .product-box-icon{
            width: 32px;
            height: 32px;        
        }
    }
}
.rouded-border{
    border-radius: 8px;
    border:1px solid #E5E5E5;
    padding-bottom: 4px;
    .top-product-box{
        padding: 8px 16px;
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        .product-img{
            width: 40px;
            height: 40px;
            object-fit: cover;
            border-radius: 4px;
            margin-right: 12px;
        }

    }
}